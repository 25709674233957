import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import './ServicesAll.scss'
import { Helmet } from "react-helmet";
import NavigatorPath from "../../Tools/NavigatorPath/NavigatorPath"
import { useNavigate } from 'react-router-dom';
import AreaData from '..//ServicesPage/AreaData'
import Checklist from '../../Images/checklist.png'
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import telephone from '../../Images/telephone.png'
import GarageImg4 from '../../Images/GarageImg4.jpg'

export default function ServicesAll() {
    const navigate = useNavigate()


    return (
        <div className="ServicesAll">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Thousand Oaks Garage Door | Our Garage Door Services</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Thousand Oaks Garage Door | Our  Services" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Thousand Oaks Garage Door | Our  Services" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.thousandoaksgaragedoor.org/services" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Discover Our Services At Thousand Oaks Garage Door Services, we understand that your garage door is more than just an entryway; it's an essential part of your home's security, functionality, and curb appeal." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Discover Our Services At Thousand Oaks Garage Door Services, we understand that your garage door is more than just an entryway; it's an essential part of your home's security, functionality, and curb appeal." data-react-helmet="true" />
                <meta name="keywords" content="Garage Dooring, Garage Door, Garage Doorinspection, Garage Door repair, Garage Door maintenance, Creosote removal, Garage Doorsafety, Garage Doorflue Repair, Soot removal, Garage Door Maintenance services" data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="Thousand Oaks Garage Door | Services" data-react-helmet="true" />
            </Helmet>
            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>GARAGE DOOR SERVICES</h1>
                    <h2>Welcome to Thousand Oaks's Premier Garage Door Service Provider</h2>
                    <h3>Discover Our Services At Thousand Oaks Garage Door Services, we understand that your garage door is more than just an entryway; it's an essential part of your home's security, functionality, and curb appeal. Whether you need a quick repair, a complete replacement, or regular maintenance, our experienced team is here to provide you with reliable and affordable solutions.</h3>
                    <span>
                        <a href="tel:805-586-9441">CALL 805-586-9441<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <span className="GoogleButt">
                        <img src={Google} alt="Thousand Oaks Garage Door Reviews" />
                        <h6>4.9 Stars - Based on 1,583 Reviews</h6>
                    </span>
                </div>
                <div className="MainBannerRight">
                    <img src={GarageImg4} alt="Expert Garage Door Services | Thousand Oaks Garage Door" />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="ServicesAllTitle">
                <h1>Thousand Oaks Garage Door Services in Thousand Oaks, CA</h1>
            </div>
            <div className="ServicesAllLsit">
                {AreaData.map((A, index) =>
                    <a href={A.Path} className="Header1BoxMenuBox">
                        
                        <div className="ServicesAllBox">
                            <h2>{A.Title}</h2>
                            <h6>{A.Des}</h6>
                            <h4>Thousand Oaks Garage Door Serving {A.Title} in Thousand Oaks, CA area and surrounding communities.</h4>
                            <h5>EXPLORE THIS SERVICE</h5>
                        </div>
                    </a>
                )}
            </div>

            <NavigatorPath />

        </div>
    )
}

